var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"file_container"},[_c('van-nav-bar',{attrs:{"title":"护理员档案","left-arrow":"","safe-area-inset-top":"","placeholder":""},on:{"click-left":function($event){return _vm.$router.push('/')}}}),_c('div',{staticClass:"search"},[_c('van-search',{attrs:{"show-action":"","shape":"round","label":"","background":"#f0f0f0","placeholder":"请输入护理员姓名或年龄"},on:{"search":_vm.onSearch,"input":_vm.input},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('div',{staticClass:"btnSearch",on:{"click":function($event){_vm.value ? _vm.onSearch() : ''}}},[_vm._v("搜索")])]},proxy:true}]),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('van-dropdown-menu',[_c('van-dropdown-item',{attrs:{"options":_vm.option1},on:{"change":function($event){return _vm.closed(_vm.value1)}},model:{value:(_vm.value1),callback:function ($$v) {_vm.value1=$$v},expression:"value1"}})],1)],1),_c('div',{staticClass:"box_container"},_vm._l((_vm.result.records),function(item,index){return _c('div',{key:index,staticClass:"box",on:{"click":function($event){_vm.detail(
          _vm.$router.push({
            path: '/detail',
            query: {
              id: item.id,
            },
          })
        )}}},[_c('div',{staticClass:"b_left"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.avatar
                ? item.avatar
                : require('../../images/61.png')}})]),_c('div',{staticClass:"b_center"},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',{staticStyle:{"color":"#999"}},[_vm._v(_vm._s(item.sex_dictText))]),_c('div',{staticStyle:{"color":"#999"}},[_vm._v(" "+_vm._s(item.workYears != "null" ? item.workYears + "年工作经验" : "暂未填写")+" ")])])]),_c('div',{staticClass:"d_right"})])}),0),(_vm.result.total > 1)?_c('div',{staticClass:"page"},[_c('van-pagination',{attrs:{"total-items":_vm.result.total,"show-page-size":3,"force-ellipses":""},on:{"change":_vm.change},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_c('van-empty',{attrs:{"description":"暂无护理人员"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }