<template>
  <div class="file_container">
    <van-nav-bar
      title="护理员档案"
      @click-left="$router.push('/')"
      left-arrow
      safe-area-inset-top
      placeholder
    />
    <div class="search">
      <van-search
        show-action
        shape="round"
        label=""
        background="#f0f0f0"
        placeholder="请输入护理员姓名或年龄"
        @search="onSearch"
        @input="input"
        v-model="value"
      >
        <template #action>
          <div @click="value ? onSearch() : ''" class="btnSearch">搜索</div>
        </template>
      </van-search>
      <van-dropdown-menu>
        <van-dropdown-item
          @change="closed(value1)"
          v-model="value1"
          :options="option1"
        />
      </van-dropdown-menu>
    </div>
    <div class="box_container">
      <div
        class="box"
        v-for="(item, index) of result.records"
        :key="index"
        @click="
          detail(
            $router.push({
              path: '/detail',
              query: {
                id: item.id,
              },
            })
          )
        "
      >
        <div class="b_left">
          <div class="img">
            <img
              :src="
                item.avatar
                  ? item.avatar
                  : require('../../images/61.png')
              "
            />
          </div>

          <div class="b_center">
            <div>{{ item.name }}</div>
            <div style="color: #999">{{ item.sex_dictText }}</div>
            <div style="color: #999">
              {{
                item.workYears != "null"
                  ? item.workYears + "年工作经验"
                  : "暂未填写"
              }}
            </div>
          </div>
        </div>

        <div class="d_right"></div>
      </div>
    </div>
    <div class="page" v-if="result.total > 1">
      <van-pagination
        v-model="currentPage"
        :total-items="result.total"
        :show-page-size="3"
        force-ellipses
        @change="change"
      />
    </div>
    <van-empty description="暂无护理人员" v-else />
  </div>
</template>

<script>
import { getNurseDetail, getLoad } from "@/api/index";
export default {
  name: "",
  components: {},
  data() {
    return {
      value: "",
      formData: {
        pageSize: 8,
      },
      result: {},
      currentPage: 1,

      value1: "",
      option1: [],
    };
  },
  created() {
    //页面数据初次请求
    getLoad().then((res) => {
      console.log(res.data.result);
      res.data.result.map((item) => {
        this.option1.push({
          text: item.name,
          value: item.id,
        });
      });
      this.value1 = this.option1[0].value;
      this.formData.storeId = this.option1[0].value;
      this.init();
    });
  },
  methods: {
    //页面数据初次请求
    init() {
      getNurseDetail(this.formData).then((res) => {
        if (res.data.code) {
          this.result = res.data.result;
          console.log(this.result);
        }
      });
    },
    closed(e) {
      this.formData.storeId = e;
      this.init();
    },
    onSearch() {
      this.formData.name = this.value;
      this.init();
    },
    input(e) {
      if (e == "") {
        this.formData.name = "";
        this.init();
      }
    },
    change(e) {
      this.formData.pageNo = e;
      this.init();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
};
</script>
<style lang="less" scoped>
.file_container {
  background-color: rgba(232, 232, 232, 0.815);
  min-height: 100vh;
}

/deep/ .van-nav-bar {
  background: #0dacf4;

  .van-nav-bar__title {
    color: #fff;
  }
}

/deep/ .van-nav-bar__left {
  .van-icon {
    color: #fff;
  }
}

/deep/ .van-search__content {
  border: 1px solid #4cc5f8;
}

/deep/ .btnSearch {
  position: relative;
  margin-left: -60px;
  background: linear-gradient(-90deg, #84f5de, #4cc5f8);
  z-index: 99;
  text-align: center;
  color: white;
  border-radius: 0 18px 18px 0;
  border: 1px solid #4cc5f8;
}

.box_container {
  width: 100%;

  .box {
    margin: auto;
    width: 316px;
    height: 102px;
    padding: 0 16px 0 10px;
    background: #ffffff;
    border-radius: 6px;
    font-size: 15px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .b_left {
      display: flex;
      align-items: center;

      .img {
        width: 74px;
        height: 74px;
        text-align: center;

        img {
          height: 100%;
          max-width: 100%;
        }
      }

      .b_center {
        margin-left: 16px;
      }
    }

    .d_right {
      width: 28px;
      height: 28px;
      background: linear-gradient(-90deg, #84f5de, #4cc5f8);
      border-radius: 50%;

      &::before {
        content: ">";
        width: 20px;
        height: 20px;
        color: #fff;
        font-size: 20px;
        font-weight: bold;
        margin-left: 6px;
      }
    }
  }
}
.page {
  margin-top: 20px;
}
</style>
